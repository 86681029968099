<template>

  <Box>
    <SpacerRow />
    <Row>
      <Column :width="5"> </Column>
      <Column :width="5">
        <Box :showBorder="true">
          <ErrorRow :error="error" />
          
          <TitleRow fontSize="xlarge"> Thank-you! </TitleRow>
          
          <SpacerRow />
          
          <ValueRow fontSize="medium"> 
            <img src="@/assets/2022/Heart.png" width="35px"/>
            Thanks for using ReadyChek.
          </ValueRow>
          
          <SpacerRow />
            
          <ValueRow fontSize="medium" > 
            We've successfully charged your credit card. 
            The charge will appear as <b>ReadyChek Inc</b> on your statement.
          </ValueRow>
            
          <SpacerRow />
          
          <TitleRow :subtitle="true"> Details </TitleRow>
          
          <Row>
            <Column>
              <Box :showBorder="true">
                <ValueRow  v-if="invoice.isNew()" fontSize="large"> Loading details for {{ invoiceNumber }}... </ValueRow>

                <LabelValueRow fontSize="medium" v-if="!invoice.isNew()">
                  <template #label>Total</template>
                  <template #value>
                    {{ amount() }}
                  </template>
                </LabelValueRow>
                
              </Box>
            </Column>
          </Row>
          
        </Box>
          
        <Box>
          <Row>
            <Column :width="12" />
            <Column :width="3">
              <router-link to="/signin" onclick="w3_close()" class="w3-bar-item w3-button rc-font-small rc-border-round">Sign In</router-link>
            </Column>
          </Row>
        </Box>
      </Column>
      <Column :width="5"> </Column>
    </Row>
      
    <SpacerRow />
  </Box>

</template>

<script>

import { mapGetters } from 'vuex'
import { uuid } from 'vue-uuid'

import Invoice from "@/domain/model/invoice/Invoice.js";

import EventUtils from '@/utils/EventUtils.js';
import InvoiceEvents from '@/domain/model/invoice/InvoiceEvents.js';
import EventsDomain from '@/domain/model/events/EventsDomain.js';

import ErrorRow   from '@/components/row/ErrorRow.vue';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import ValueRow from "@/portals/shared/library/value/ValueRow.vue";
import LabelValueRow from "@/portals/shared/library/labelvalue/LabelValueRow.vue";

export default {
  name: "operator-billing-paid",
  components: {
    Box,Row,Column,
    ErrorRow,
    TitleRow, SpacerRow,
    ValueRow, LabelValueRow,
  },
  props: {
  },
  data() {
    return {
      invoiceNumber: "",
      isSaving: false,
      invoiceDataLatest: null,
      error: null,
    };
  },
  computed: {
    ...mapGetters([
                   'domain',
                   'InvoiceStorage',
                   'InvoiceStorage_size',
                   'auth_connected',
                   'auth_client',
                ]),
    invoice: function() {
      if (this.invoiceDataLatest) {
        return new Invoice(this.domain, this.invoiceDataLatest);
      }
      return new Invoice(this.domain, {});
    },
  },
  watch: {
    auth_connected() {
      if (this.auth_connected) {
        this.paid();
      }
    },
    InvoiceStorage_size() {
      for (var i = 0; i < this.InvoiceStorage.list.length; i++) {
        var invoice = this.InvoiceStorage.list[i];
        if (invoice.number === this.invoiceNumber) {
          this.invoiceDataLatest = invoice;
          this.isSaving = false;
        }
      }
    },
  },
  mounted: function () {
    this.invoiceNumber = this.$route.query.invoiceNumber;
    this.paid();
  },
  methods: {
    time: function() {
      return this.invoice.modifiedDateV2().displayAs_YYYY_MM_DD();
    },
    amount: function() {
      return "$" + this.invoice.totalWithTaxes().toFixed(2);
    },
    paid: function() {
      if (this.isSaving) {
        return;
      }
      if (this.auth_connected) {
        this.sendPaid();
      } else {
        this.$connect();  
      }
    },
    sendPaid: function() {
      this.isSaving = true;
      this.error = null;
      
      const requestEvent = InvoiceEvents.Paid.Request(this.invoice);
      requestEvent[Invoice.Fields.NUMBER] = this.invoiceNumber;
      EventsDomain.initEvent(requestEvent, null, uuid.v1(), this.auth_client.deviceId, this.auth_client.version);
      EventUtils.Send.event(this.$socket, requestEvent);
    },
  }
}
</script>